//
//
//
//
//
//
//
//
//
//
//

import TreeView from "@/components/TreeView/TreeView.vue";
export default {
  props: {
    category: {
      type: Object
    },
    value: {
      type: Object,
      default: null
    },
    mode: {
      type: String,
      validate: v => ["links", "checkboxes"].includes(v),
      default: "links"
    }
  },
  data() {
    return {
      root: {},
      nodesByIds: {}
    };
  },
  watch: {
    root: {
      deep: true,
      handler(v) {
        this.$emit("input", v);
      }
    }
  },
  components: {
    TreeView
  },
  methods: {
    getLink(item) {
      return {
        name: "CategoryInfo",
        params: {
          c_id: item.id
        }
      };
    },
    createNode(item) {
      const node = {
        id: item.c_id,
        title: item.c_name,
        show: false,
        child: item.qHasChild ? null : [],
        ...(this.mode === "checkboxes" && {
          checked: false
        })
      };
      this.nodesByIds[item.c_id] = node;
      return node;
    },
    async load(c_id) {
      let data = await this.$store.dispatch("category/getCategoryList", {
        c_id,
        mp: this.$route.params.mp
      });
      this.nodesByIds[c_id].child = this.nodesByIds[c_id].child || [];
      data.forEach(item => {
        const node = this.createNode(item);
        if (this.nodesByIds[c_id]) {
          if (!this.nodesByIds[c_id].child.find(i => i.id === node.id)) {
            this.nodesByIds[c_id].child.push(node);
          }
        }
        this.nodesByIds[item.c_id] = node;
      });
    },
    walk(node, fn) {
      fn(node);
      if (node.child) {
        node.child.forEach(child => this.walk(child, fn));
      }
    },
    onCheck(node) {
      // recursively check whole branch
      this.walk(this.nodesByIds[node.id], item => item.checked = true);
    },
    onUncheck(node) {
      // recursively uncheck whole branch
      this.walk(this.nodesByIds[node.id], item => item.checked = false);
    },
    async onShow(node) {
      this.nodesByIds[node.id].show = true;
      if (!this.nodesByIds[node.id].child) {
        await this.load(node.id);
        // recursively check childs that not exists at onCheck call
        if (this.mode === "checkboxes" && this.nodesByIds[node.id].checked) {
          this.nodesByIds[node.id].child.forEach(item => item.checked = true);
        }
      }
    },
    onHide(node) {
      this.nodesByIds[node.id].show = false;
    }
  },
  mounted() {
    if (this.value) {
      this.root = this.value;
      this.walk(this.root, node => {
        this.nodesByIds[node.id] = node;
      });
      return;
    }
    this.root = this.createNode(this.category);
    this.root.show = true;
    this.load(this.category.c_id);
  }
};
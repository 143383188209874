//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TreeNode from "./Node";
import Vue from "vue";
Vue.component("tree-node", TreeNode);
export default {
  props: {
    nodes: {
      type: Object
    },
    mode: {
      type: String,
      validate: v => ["links", "checkboxes"].includes(v),
      default: "links"
    },
    getLink: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      scrollSettings: {
        suppressScrollX: true,
        suppressScrollY: false,
        wheelPropagation: false
      },
      checkedIds: []
    };
  }
};
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MainPage from "@/components/MainPage.vue";
import MainTitle from "@/components/MainTitle.vue";
import CategoryCatalog from "@/components/Category/Catalog.vue";
import EntitySearch from "@/components/EntitySearch.vue";
import CategoriesTable from "@/components/Rating/CategoriesTable";
import { dateRangeFromDate, today, dateRangeBoundaries } from "@/components/RangeCalendar.vue";
import CalendarMixin from "@/mixins/Calendar";
export default {
  name: "Home",
  mixins: [CalendarMixin],
  meta() {
    return {
      title: this.$route.params.mp === "wb" ? "Аналитика и статистика продаж маркетплейса Wildberries, анализ лучших категорий и ниш для продаж на Вайлдберриз, анализ конкурентов" : "Аналитика и статистика продаж маркетплейса OZON, анализ лучших категорий и ниш для продаж на ОЗОН, анализ конкурентов",
      meta: [{
        name: "description",
        content: this.$route.params.mp === "wb" ? "SalesFinder - сервис детальной аналитики товаров Вайлдберриз! Анализ категорий, подбор ниш и товаров для продажи, анализ конкурентов Wildberries, поисковые запросы, кабинет продавца по API" : "SalesFinder - сервис детальной аналитики товаров OZON! Анализ категорий, подбор ниш и товаров для продажи, анализ конкурентов ОЗОН, поисковые запросы, кабинет продавца по API"
      }]
    };
  },
  data() {
    return {};
  },
  components: {
    MainPage,
    MainTitle,
    CategoryCatalog,
    EntitySearch,
    CategoriesTable
  },
  computed: {
    ratingBindings() {
      var _this$calendarBoundar;
      if (!this.calendarBoundaries) {
        return;
      }
      const lastAvailableDate = ((_this$calendarBoundar = this.calendarBoundaries) === null || _this$calendarBoundar === void 0 ? void 0 : _this$calendarBoundar[1]) || today();
      const weekRange = dateRangeFromDate(lastAvailableDate, 30);
      const [date, date2] = dateRangeBoundaries(weekRange, this.calendarBoundaries);

      //console.log('90: date=',date, 'date2=', date2)
      //console.log('91: this.route=',this.$route)

      return {
        action: "rating/getRatingCategories",
        id: this._uid,
        routeParams: this.$route.params,
        reportSettings: {
          mp: this.$route.params.mp,
          metric: "revenue",
          date,
          date2,
          size: 20,
          ...this.$route.query
        },
        paginatable: false
      };
    }
  },
  methods: {
    searchLoader(query) {
      return this.$store.dispatch("category/getCategoryByName", {
        query,
        mp: this.$route.params.mp
      });
    },
    getRoute(item) {
      return {
        name: "CategoryInfo",
        params: {
          c_id: item.c_id,
          mp: this.$route.params.mp
        }
      };
    }
  }
};